import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import SideBar from '../SideBar'
import GreenBar from '../GreenBar'
import '../../css/general.css'
import '../../css/contact.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import emailjs from 'emailjs-com';
import Alert from 'react-bootstrap/Alert'

import ContactPageBrushes from './../../Assets/images/ContactPageImage.jpg'
export default function Contact() {
    let [formObject, setFormObject] = useState({})
    let [name, setName] = useState('')
    let [email, setEmail] = useState('')
    let [message, setMessage] = useState('')
    let [showAlert, setShowAlert] = useState(false)

    function handleChange(key, value) {
        setFormObject({
            ...formObject,
            [key]: value
        })
    }
    function deleteInputData() {
        setFormObject({})
        setName('')
        setEmail('')
        setMessage('')
    }
    function sendEmail(e) {
        e.preventDefault()
        fetch("https://clingonaustralia.com.au/contact", {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                "name": formObject.name,
                "email": formObject.email,
                "message": formObject.message,
                
            })
        })
        
        .catch(err => console.log(err))
        deleteInputData()
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 3000)
        
        
    }
    return (
        <Container>
            <Row>
                {showAlert && <Alert variant='success'>Message Sent!</Alert>}
                <Col lg={{ span: 9 }} md={{ span: 12 }} className='col-left'>
                    <h1>CONTACT US!</h1>
                    <hr />
                    <Form className='form' onSubmit={(e) => sendEmail(e)}>

                        <Form.Group>
                            <Form.Label >Name <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={name} name='name' onChange={(e) => {
                                setName(e.target.value)
                                handleChange(e.target.name, e.target.value)
                                }} type='' required/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Email <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={email} type='email' name='email' onChange={(e) => {
                                setEmail(e.target.value)
                                handleChange(e.target.name, e.target.value)}} required/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Message <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={message} name='message' onChange={(e) => {
                                setMessage(e.target.value)
                                handleChange(e.target.name, e.target.value)}} as='textarea' required/>
                        </Form.Group>
                        <Button variant='primary' type='submit'>Submit</Button>
                    </Form>
                    


                    <p><b>Note:</b> Please fill out all fields.</p>
                    <hr />
                    <Row>
                        <Col className='col-left'>
                            <img className='pictureFormat sizeFix' src={ContactPageBrushes} />

                        </Col>
                        <Col className='col-right'>
                            <h4><b>Hayley Justice - Australian Distributor</b></h4>
                            <address><br />Address: <br />PO Box 213, <br />Wonthaggi, 3995  <br />Victoria<br />Phone: 0439376904<br />Email: <a href="mailto:clingonaustralia@gmail.com" style={{color: 'blue'}}>clingonaustralia@gmail.com</a><br />ABN: 44 331 876 608 <br />  </address>
                        </Col>

                    </Row>

                </Col>
                <Col lg={{ span: 3 }} className="col-right">
                    <SideBar />
                    <GreenBar />
                </Col>
            </Row>

        </Container>
    )
}