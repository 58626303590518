import React from 'react'
import Button from 'react-bootstrap/Button'
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
    Link,
    NavLink
} from "react-router-dom";
import SideBar from '../SideBar';
import GreenBar from '../GreenBar'
import '../../css/general.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

export default function OrderConfirmation({loggedInUser}) {
    return (
        <div style={{minHeight: '90vh'}}>
        <Container >
                <Alert className='alert' variant='success' style={{width: '90%', marginTop: '10%'}}>
                    <h1>ORDER CONFIRMED</h1>
                    <p style={{textAlign: 'center'}}>Thank you for your order!</p>
                    <hr />
                        <p>A confirmation will be sent to your email followed by an invoice including freight costs.</p>
                        <p>Your order will be sent once your invoice has been paid</p>


                </Alert>
                
        </Container>
        </div>
    )
}