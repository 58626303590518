import React, {useEffect, useState, useHistory} from 'react'
import NavigationBar from './components/NavBar'
import './css/general.css'
import {
  BrowserRouter as Router,
} from "react-router-dom";


function App() {

  var [loggedInUser, setLoggedInUser] = useState(localStorage.getItem('loggedInUser') != 'false')

  function checkIsLoggedIn() {
    fetch("https://clingonaustralia.com.au/user")
    .then(resp => resp.json())
    .then(data => {
        localStorage.setItem('loggedInUser', JSON.stringify(data))
    })
    .catch(e => {
      localStorage.setItem('loggedInUser', false)
      setLoggedInUser(false)
      })

      if (localStorage.getItem('loggedInUser') != 'false') {

        return true
      } else {
        return false

      }
  }

  
  useEffect(() => {
    checkIsLoggedIn()
    
}, [])
  return (

    <div className="App">
        <Router>
          <NavigationBar setLoggedInUser={setLoggedInUser} loggedInUser={loggedInUser} checkIsLoggedIn={checkIsLoggedIn}/>
        </Router>


    </div>
  );
}

export default App;
