import React, { useState } from 'react'
import Product from './pages/Product'
import Login from './pages/Login'
import BecomeRetailer from './pages/BecomeRetailer'
import Contact from './pages/Contact'
import FindRetailer from './pages/FindRetailer'
import ProductContainer from './ProductContainer'
import WhyCustomersLoveClingOn from './pages/Love'
import Store from './pages/Store'
import Home from './pages/Home'
import PasswordReset from './pages/PasswordReset'
import '../css/general.css'
import { stack as Menu } from 'react-burger-menu'
import { reveal as CartMenu } from 'react-burger-menu'
import Alert from 'react-bootstrap/Alert'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useHistory
} from "react-router-dom";
import burgerStyles from '../css/burgerMenu.css'
import styles from '../css/appstyle.module.css'
import globalStyles from '../Assets/global-styles/bootstrap.min.module.css'
import cx from 'classnames'
import general from '../css/general.css'
import TipsAndTricks from './pages/TipsAndTricks'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import OrderConfirmation from './pages/OrderConfirmation'
var test = document.documentElement.scrollHeight;

export default function NavigationBar({checkIsLoggedIn, setLoggedInUser, loggedInUser}) {
  var [open, setOpen] = useState(false)
  var [cartOpen, setCartOpen] = useState(false)

  var [logOutMessage, setLogOutMessage] = useState(false)
  var [loggedIn, setLoggedIn] = useState(false)
  var [passwordResetConfirmed, setPasswordResetConfirmed] = useState(false)
  let history = useHistory()
  let [sessionExpired, setSessionExpired] = useState()
  let handleClick = () => {
    setOpen(false)
  }
  let handleCartClick = () => {
    setCartOpen(false)
  }
  var testStyles = {
    bmBurgerButton: {
      display: 'none',
      position: 'relative',
      width: '36px',
      height: '200px',
      left: '36px',
      top: '36px'
    },
  }
    function sessionExpire() {
        setSessionExpired(true)
        setTimeout(() => setSessionExpired(false), 5000)

    }
  var getUserInformation = () => {
    fetch('https://clingonaustralia.com.au/user', {
      method: 'GET',
      header: {
        'Content-Type': 'application/json'
      }
    })
      .then(resp => resp.json())
      .then(data => {

        
        setLoggedInUser(data)
        localStorage.setItem('loggedInUser', JSON.stringify(data));


        localStorage.setItem('userData', JSON.stringify(data));
      })
      .catch(err => console.log(err))
  }
  var handleLogout = () => {
    fetch('https://clingonaustralia.com.au/logout')
      .then(data => {
        localStorage.setItem('loggedInUser', false)
        history.push('/')
        setLoggedInUser(false)
        setLogOutMessage(true)
        setTimeout(() => setLogOutMessage(false), 3000)
        
      })
      .catch(err => console.log(err))
  }
  var passwordResetSuccessful = () => {
    setPasswordResetConfirmed(true)
    setTimeout(() => setPasswordResetConfirmed(false), 3000)

  }
  return (
    <>

      <div className={styles.navBarWrapper}>
        <nav className={styles.navigationBar}>
          <div className={styles.menuWrapper}>
          <NavLink exact className={styles.menuItem} to='/'><b>HOME</b></NavLink>
          <NavLink className={styles.menuItem} to='/products'><b>THE BRUSHES</b></NavLink>
          <NavLink className={styles.menuItem} to='/find-a-retailer'><b>FIND A RETAILER</b></NavLink>
          <NavLink className={styles.menuItem} to='/become-a-retailer'><b>BECOME A RETAILER</b></NavLink>
          <NavLink className={styles.menuItem} to='/why-customers-love-cling-on'><b>WHY CLING ON?</b></NavLink>
          <NavLink className={styles.menuItem} to='/contact'><b>CONTACT</b></NavLink>
          <NavLink className={styles.menuItem} to='/tips-and-tricks'><b>TIPS & TRICKS</b></NavLink>
          {loggedInUser && <NavLink className={styles.menuItem} to='/store'><b>STORE</b></NavLink>}
          {!loggedInUser && <NavLink className={styles.menuItem} to='/login'><b>LOGIN</b></NavLink>}

          {loggedInUser && <NavLink  className={styles.menuItem} to='/' onClick={() => handleLogout()}><b>LOGOUT</b></NavLink>}

          </div>
        </nav>

      </div>
      <div className={cx(globalStyles.navbar, globalStyles['nav-link'], styles.burgerMenu)}>
        <Menu onClose={() => setOpen(false)} onOpen={() => setOpen(true)} isOpen={open} width={'80%'}>
          <NavLink exact to='/' onClick={handleClick} className={`menu-item`}><b>HOME</b></NavLink>
          <NavLink to='/products' onClick={handleClick} className={`menu-item`}><b>THE BRUSHES</b></NavLink>
          <NavLink to='/find-a-retailer' onClick={handleClick} className={`menu-item`}><b>FIND A RETAILER</b></NavLink>
          <NavLink to='/become-a-retailer' onClick={handleClick} className={`menu-item`}><b>BECOME A RETAILER</b></NavLink>
          <NavLink to='/why-customers-love-cling-on' onClick={handleClick} className={`menu-item`}><b>WHY CLING ON?</b></NavLink>
          <NavLink to='/contact' onClick={handleClick} className={`menu-item`}><b>CONTACT</b></NavLink>
          <NavLink to='/tips-and-tricks' onClick={handleClick} className={`menu-item`}><b>TIPS & TRICKS</b></NavLink>
          {loggedInUser && <NavLink onClick={handleClick} className={`menu-item`} to='/store'><b>STORE</b></NavLink>}
          {!loggedInUser && <NavLink onClick={handleClick} className={`menu-item`} to='/login'><b>LOGIN</b></NavLink>}
          {loggedInUser && <NavLink  onClick={() => handleLogout()} className={`menu-item`} to='/' ><b>LOGOUT</b></NavLink>}

        </Menu>
      </div>
    
      {logOutMessage && <Alert variant='success'>You have been successfully logged out</Alert>}

      {passwordResetConfirmed && <Alert variant='success'>Password reset was successful</Alert>}
      <Switch>

        <Route exact path={'/'} ><Home /></Route>
        <Route path={'/contact'} ><Contact /></Route>
        <Route path={'/become-a-retailer'}><BecomeRetailer /></Route>
        <Route path={'/find-a-retailer'}><FindRetailer /></Route>
        <Route path={'/why-customers-love-cling-on'} ><WhyCustomersLoveClingOn /></Route>
        <Route path={'/products'}> <ProductContainer /> </Route>
        <Route path={'/store'}> {loggedInUser ? <Store sessionExpire={sessionExpire} loggedInUser={loggedInUser} checkIsLoggedIn={checkIsLoggedIn}/> : <Redirect to='/login' sessionExpired={sessionExpired}/>} </Route>
        <Route path={'/login'}> {!loggedInUser ? <Login sessionExpired={sessionExpired}/>  : <Redirect to='/store' />} </Route>
        <Route path={'/tips-and-tricks'}><TipsAndTricks /></Route>
        <Route path={'/password-reset'}><PasswordReset passwordResetSuccessful={passwordResetSuccessful}/></Route>
        <Route path={'/order-confirmation'}><OrderConfirmation userInfo={loggedInUser}/></Route>






      </Switch>
      <footer className="bg-light text-center text-lg-start">
        <div className={cx(globalStyles['text-center'], globalStyles['p-3'], styles.footerBackground)}>


          © {new Date().getFullYear()} Copyright: DJ
        {loggedInUser && <NavLink onClick={handleClick} className={styles.menuItem} style={{float: 'right', color: 'black'}} to='/password-reset'><small>PASSWORD RESET</small></NavLink>}
          

        </div>
      </footer>
    </>

  )
}