import React, { useState, useEffect, Component } from 'react'
import StoreProductDisplay from '../StoreProductDisplay'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cart from '../Cart'
import { useHistory } from "react-router-dom";
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Alert from 'react-bootstrap/Alert'
import CartIcon from '../../Assets/images/cartIcon.png';

export default function Store({ sessionExpire, loggedInUser, checkIsLoggedIn }) {
    
    let history = useHistory();

    let [order, setOrder] = useState([])
    let [orderForEmail, setOrderForEmail] = useState([])
    let [orderSubtotal, setOrderSubtotal] = useState(0)
    let [stockData, setStockData] = useState([])
    let [secondsData, setSecondsData] = useState([])
    let [reloadForm, setReloadForm] = useState(false)
    let [error, setError] = useState(false)
    
    let [emailError, setEmailError] = useState(false)
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    var subtotal = () => {
        let num = 0
        if (order.length > 0) {


            order.forEach(each => {
                num += (each.price * each.qty)
            })
            setOrderSubtotal(num.toFixed(2))
        } else {
            setOrderSubtotal(0)
        }
    }
    var totalNumOfBrushes = (order) => {
        var numOfBrushes = 0
        localStorage.setItem('test', JSON.stringify(order))
        order.forEach((brush) => {
            numOfBrushes += brush.qty
        })

        return numOfBrushes
    }
    var clearOrder = () => {
        let test = window.confirm('Are you sure you would like to clear selections?')
        if (test) {
            localStorage.clear()
            localStorage.setItem('loggedInUser', true)
            window.location.reload(false)
        } else {

        }

    }
    var clearOrderAfterConfirm = () => {

        localStorage.clear()
        localStorage.setItem('loggedInUser', true)


    }
    var updateOrder = (newItem) => {
        
        if (order.length == 0) {
            if (localStorage.getItem('test')) {
                order = JSON.parse(localStorage.getItem('test'))
            }
            else {
                order = []
            }

        }
        order = removeDuplicates(newItem, order)
        subtotal()

        localStorage.setItem('test', JSON.stringify(order))
    }
    var updateOrderForEmail = (newItem) => {

        test = removeDuplicates(newItem, test)
        test.map((value, index) => {
            if (value.qty == 0) {
                test.splice(index, 1)
            }
        })
        localStorage.setItem('test', JSON.stringify(test))
    }
    var updateOrderSubmitted = () => {
        history.push('/order-confirmation')
    }
    var updateReloadForm = () => {
        setReloadForm(true)
    }
    var removeDuplicates = (objectToInsert, data) => {
        var objectToUpdate = data.find(match => match.id == objectToInsert.id)
        if (objectToUpdate) {
            data.forEach((item, index) => {
                if (item.id == objectToUpdate.id) {
                    if (objectToUpdate.qty > 0 || objectToUpdate.qty > 0) {
                        return data[index] = objectToInsert

                    } else {

                        return data.splice(index, 1)
                    }
                }
            })
        } else {
            data.push(objectToInsert)
        }

        return data
    }
    let phoneAlert = () => {
        window.confirm('Something went wrong when submitting your order. Please try again later, log out and back in, or send a screenshot of your order to clingonaustralia@gmail.com')
    }
    let getStock = () => {
        fetch('https://clingonaustralia.com.au/stock', {
            method: 'GET',
            header: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
            .then(resp => resp.json())
            .then(data => {
                var secondsList = []
                var stockList = []
                var madelines = []
                data.forEach(e => {
                    var splitList = e.code.split(" ")
                    if (splitList.includes("SECONDS") || splitList.includes("seconds") || splitList.includes("Seconds")) {
                        secondsList.push(e)
                    }
                    else {
                        stockList.push(e)
                    }
                })

                setSecondsData(secondsList)
                setStockData(stockList)})
            .catch(err => console.error(err))
    }
    let secondsMessage = (margin) => {
        return <div style={{backgroundColor: "white", marginLeft: margin, padding: '1%'}}>
        <h3>BRUSH SECONDS</h3>
        <p style={{textAlign: "left"}}>A limited number of 'SECONDS' have accumulated over the last few years and need to be moved on. <br/>Available here until sold out! <br />
<b>SECONDS may NOT be sold to customers!</b> Selling imperfect brushes to customers gives Cling On! a bad name. <br/>They may be used for workshops or for personal use only. <br />
Seconds have a variety of imperfections suffered during transit: scratched logos, small chips (which have been touched up by me), minor damage on the handles, dull paintwork etc. Some are just missing the shrink wrap.</p>
        
        </div>
    } 
    useEffect(() => {
        getStock()
        checkIsLoggedIn()
    }, [])
    let test = localStorage.getItem('test')
    test = test ? JSON.parse(test) : []
    return (
        <Container>
            {window.innerWidth < 992 &&
                <div>

                    <img src={CartIcon} onClick={() => setState({ isPaneOpen: true })} style={{ position: 'fixed', right: '10px', top: '20px', width: '60px', transform: 'scaleX(-1)' }} />
                    <SlidingPane
                        className="some-custom-class"
                        overlayClassName="some-custom-overlay-class"
                        isOpen={state.isPaneOpen}
                        title={<p style={{ color: 'black', fontSize: '30px', marginTop: '15px' }}>My Order</p>}
                        onRequestClose={() => {
                            // triggered on "<" on left top click or on outside click
                            setState({ isPaneOpen: false });
                        }}
                    >
                        <Cart sessionExpire={sessionExpire} checkIsLoggedIn={checkIsLoggedIn} isPhone={true} setError={setError} error={error} totalNumOfBrushes={totalNumOfBrushes} phoneError={phoneAlert} clearOrder={clearOrder} clearOrderAfterConfirm={clearOrderAfterConfirm} test={test} orderForEmail={orderForEmail} loggedInUser={loggedInUser} updateReloadForm={updateReloadForm} updateOrderSubmitted={updateOrderSubmitted} orderSubtotal={orderSubtotal} />

                    </SlidingPane>

                </div>}
            <Row>
                <Col lg={{ span: 8 }} >
                    <h3 style={{ width: '84%', marginTop: '25px' }}>Cling On! Wholesale Pricelist {new Date().getFullYear()} <small style={{ fontSize: '15px' }}>Prices include GST</small></h3>
                    {emailError && <Alert variant='danger'>Something went wrong when submitting your order. Please try again later or send a screenshot of your order to clingonaustralia@gmail.com</Alert>}
                    {window.innerWidth >= 985 &&
                        stockData ? stockData.map((product) => {
                            return <StoreProductDisplay setEmailError={setEmailError} setError={setError} reloadForm={reloadForm} data={product} updateOrderForEmail={updateOrderForEmail} updateOrder={updateOrder} />
                        }) : ""
                    }
                    {secondsData.length > 0 && window.innerWidth >= 985 && secondsMessage("0.6%") }
                    {window.innerWidth >= 985 &&
                        secondsData ? secondsData.map((product) => {
                            return <StoreProductDisplay setEmailError={setEmailError} setError={setError} reloadForm={reloadForm} data={product} updateOrderForEmail={updateOrderForEmail} updateOrder={updateOrder} />
                        }) : ""
                    }
                    {window.innerWidth < 985 && <ul>
                        {stockData ? stockData.map((product) => {
                            return <StoreProductDisplay setEmailError={setEmailError} setError={setError} reloadForm={reloadForm} data={product} updateOrderForEmail={updateOrderForEmail} updateOrder={updateOrder} />
                        }) : <li></li>}
                    </ul>}
                    {secondsData.length > 0 && window.innerWidth < 985 &&  secondsMessage("5.4%")}
                    {window.innerWidth < 985 && 
                    <h3 style={{backgroundColor: "white"}}>BRUSH SECONDS</h3> &&
                    <ul>
                        {secondsData ? secondsData.map((product) => {
                            return <StoreProductDisplay setEmailError={setEmailError} setError={setError} reloadForm={reloadForm} data={product} updateOrderForEmail={updateOrderForEmail} updateOrder={updateOrder} />
                        }) : <li></li>}
                    </ul>}
                </Col>
                <Col lg={{ span: 4 }} >
                    {window.innerWidth >= 985 && <>
                        <h3 style={{ marginTop: '28px' }}>My Order</h3>
                        <div style={{ backgroundColor: 'white', padding: '10px' }}>
                            <Cart sessionExpire={sessionExpire} checkIsLoggedIn={checkIsLoggedIn} isPhone={false} setError={setError} error={error} totalNumOfBrushes={totalNumOfBrushes}  setEmailError={setEmailError} clearOrder={clearOrder} clearOrderAfterConfirm={clearOrderAfterConfirm} test={test} orderForEmail={orderForEmail} loggedInUser={loggedInUser} updateReloadForm={updateReloadForm} updateOrderSubmitted={updateOrderSubmitted} orderSubtotal={orderSubtotal} />
                        </div></>}
                </Col>

            </Row>
        </Container>
    )
}


