import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { products } from '../Assets/data.js'

import styles from "../css/greenBar.module.css"
import "../css/general.css"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import R12 from '../Assets/images/R12Thumb.jpg'
import R14 from '../Assets/images/R14Thumb.jpg'
import R16 from '../Assets/images/R16Thumb.jpg'
import R18 from '../Assets/images/R18Thumb.jpg'
import R20 from '../Assets/images/R20Thumb.jpg'
import O35 from '../Assets/images/035Thumb.jpg'
import O40 from '../Assets/images/040Thumb.jpg'
import O45 from '../Assets/images/045Thumb.jpg'
import F30 from '../Assets/images/F30Thumb.jpg'
import F40 from '../Assets/images/F40Thumb.jpg'
import F50 from '../Assets/images/F50Thumb.jpg'
import FA40 from '../Assets/images/FA40Thumb.jpg'
import SA50 from '../Assets/images/SA50Thumb.jpg'
import S30 from '../Assets/images/S30Thumb.jpg'
import S50 from '../Assets/images/S50Thumb.jpg'
import PS10 from '../Assets/images/PS10Thumb.jpg'
import PS14 from '../Assets/images/PS14Thumb.jpg'
import P16 from '../Assets/images/P16Thumb.jpg'
import P20 from '../Assets/images/P20Thumb.jpg'
import P24 from '../Assets/images/P24Thumb.jpg'
import B10 from '../Assets/images/B10Thumb.jpg'
import B12 from '../Assets/images/B12Thumb.jpg'
import Fiftypk from '../Assets/images/FlyerThumb.jpg'
import Madeline_Medium from '../Assets/images/MadelineMedThumb.jpg'
import Madeline_Mini_Flat from '../Assets/images/MadelineMiniFlatThumb.jpg'
import Madeline_Mini_Tapered from '../Assets/images/MadelineMiniTaperedThumb.jpg'
import R12SECONDS from '../Assets/images/R12WebSeconds.jpg'
import R14SECONDS from '../Assets/images/R14WebSeconds.jpg'
import R16SECONDS from '../Assets/images/R16WebSeconds.jpg'
import R18SECONDS from '../Assets/images/R18WebSeconds.jpg'
import R20SECONDS from '../Assets/images/R20WebSeconds.jpg'
import O35SECONDS from '../Assets/images/035WebSeconds.jpg'
import O40SECONDS from '../Assets/images/040WebSeconds.jpg'
import O45SECONDS from '../Assets/images/045WebSeconds.jpg'
import F30SECONDS from '../Assets/images/F30WebSeconds.jpg'
import F40SECONDS from '../Assets/images/F40WebSeconds.jpg'
import F50SECONDS from '../Assets/images/F50WebSeconds.jpg'
import FA40SECONDS from '../Assets/images/FA40WebSeconds.jpg'
import PS10SECONDS from '../Assets/images/PS10WebSeconds.jpg'
import PS14SECONDS from '../Assets/images/PS14WebSeconds.jpg'
import P16SECONDS from '../Assets/images/P16WebSeconds.jpg'
import P20SECONDS from '../Assets/images/P20WebSeconds.jpg'
import P24SECONDS from '../Assets/images/P24WebSeconds.jpg'
export default function StoreProductDisplay({ setEmailError, setError, data, updateOrder, updateOrderForEmail }) {
    let tempWidth = window.innerWidth > 520 ? '17rem' : '45vw';
    let {
        price,
        rrp,
        code,
        id
    } = data
    let imports = {
        'R12': R12,
        'R14': R14,
        'R16': R16,
        'R18': R18,
        'R20': R20,
        '035': O35,
        '040': O40,
        '045': O45,
        'F30': F30,
        'F40': F40,
        'F50': F50,
        'FA40': FA40,
        'SA50': SA50,
        'S30': S30,
        'S50': S50,
        'PS10': PS10,
        'PS14': PS14,
        'P16': P16,
        'P20': P20,
        'P24': P24,
        'B10': B10,
        'B12': B12,
        'R12 SECONDS': R12SECONDS,
        'R14 SECONDS': R14SECONDS,
        'R16 SECONDS': R16SECONDS,
        'R18 SECONDS': R18SECONDS,
        'R20 SECONDS': R20SECONDS,
        '035 SECONDS': O35SECONDS,
        '040 SECONDS': O40SECONDS,
        '045 SECONDS': O45SECONDS,
        'F30 SECONDS': F30SECONDS,
        'F40 SECONDS': F40SECONDS,
        'F50 SECONDS': F50SECONDS,
        'FA40 SECONDS': FA40SECONDS,
        'PS10 SECONDS': PS10SECONDS,
        'PS14 SECONDS': PS14SECONDS,
        'P16 SECONDS': P16SECONDS,
        'P20 SECONDS': P20SECONDS,
        'P24 SECONDS': P24SECONDS,
        '50pk Flyers': Fiftypk,
        'Madeline Medium': Madeline_Medium,
        'Madeline Mini Flat': Madeline_Mini_Flat,
        'Madeline Mini Tapered': Madeline_Mini_Tapered

    }
    var fontSize = data.code.split('').length >= 5 ? 97 : 130
    if (data.code == "P20 SECONDS") {
        return <></>
    }
    return (
        <div style={{ padding: '5px', display: window.innerWidth >= 992 ? 'inline-block' : 'block' }} className='clearFix'>
            {window.innerWidth >= 992 &&
                <Card style={{ width: tempWidth, minHeight: '250px' }}>
                    <Row>
                        <Col lg={{ span: 5 }}>
                            <Card.Body className="store-brush-display">
                                <Card.Title style={{ fontSize: `${fontSize}%`, textAlign: 'justify' }}>{data.code}</Card.Title>
                                <Card.Text style={{ textAlign: 'left' }}><b>Price: </b>${data.price}</Card.Text>
                                <Card.Text style={{ textAlign: 'left' }}><b>RRP: </b>${data.rrp != 0 ? data.rrp : "N/A"}</Card.Text>
                                {data.code != "Madeline Mini Tapered" &&<form onSubmit={(e) => { e.preventDefault() }}>
                                    <input onSubmit={(e) => { e.preventDefault() }} onChange={(e) => {
                                        setEmailError(false)
                                        setError(false)
                                        let qty = parseInt(e.target.value)
                                        qty = qty <= 0 ? 0 : qty
                                        localStorage.setItem(code, qty)
                                        updateOrderForEmail({ "id": id, "qty": qty })
                                        updateOrder({ code, price, rrp, id, qty })

                                    }} type='number' value={localStorage.getItem(code) <= 0 ? '' : localStorage.getItem(code)} style={{ width: '100%' }} placeholder='Qty' />
                                </form>}
                            </Card.Body>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            {data.code != "Madeline Mini Tapered" ? <img style={{ height: '200px', width: '100px', marginTop: '10px' }} src={imports[code]} className={styles.image} />
                                : <p style={{ height: '200px', width: '100px', marginTop: '10px' }} className="outOfStockLabel">OUT OF STOCK</p>}
                        </Col>
                    </Row>
                </Card>
            }
            {window.innerWidth < 992 && <li style={{ listStyle: 'none', backgroundColor: 'white', }}>

                <Row>
                    <Col>
                    {data.code != "Madeline Mini Tapered" ? <img style={{ height: '200px', width: '100px', marginTop: '10px' }} src={imports[code]} className={styles.image} />
                            : <p style={{ height: '200px', width: '100px', marginTop: '10px' }} className="outOfStockLabel">OUT OF STOCK</p>}
                    </Col>
                    <Col>
                        <p style={{ fontSize: `${fontSize}%`, textAlign: 'left', marginTop: '15%', textAlign: 'justify' }}>{data.code}</p>
                        <p style={{ textAlign: 'left' }}><b>Price: </b>${data.price}</p>
                        {data.rrp != 0 && <p style={{ textAlign: 'left' }}><b>RRP: </b>${data.rrp}</p>}
                    </Col>
                    <Col>
                    {data.code != "Madeline Mini Tapered" && <form style={{ marginTop: '50%', width: '50%' }} onSubmit={(e) => { e.preventDefault() }}>
                            <input onChange={(e) => {
                                let qty = parseInt(e.target.value)

                                qty = isNaN(qty) ? 0 : qty
                                qty = qty < 0 ? 0 : qty
                                localStorage.setItem(code, qty)
                                updateOrderForEmail({ "id": id, "qty": qty })
                                updateOrder({ code, price, rrp, id, qty })

                            }} type='number' value={localStorage.getItem(code) <= 0 ? '' : localStorage.getItem(code)} style={{ width: '100%' }} placeholder='Qty' />
                        </form>}
                    </Col>
                </Row>

            </li>}

        </div>
    )
}