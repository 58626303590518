import React, { useState, useEffect } from 'react'

import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";
import '../../css/general.css'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
} from "react-router-dom";
import '../../css/general.css'
import Spinner from 'react-bootstrap/Spinner';
export default function Login() {
    let [password, setPassword] = useState('')
    let [userName, setUserName] = useState('')
    let [error, setError] = useState(false)
    let [sessionExpired, setSessionExpired] = useState(false)
    let [loading, setLoading] = useState(false)

    let history = useHistory();

    let handleSubmit = async () => {
        setLoading(true)
        await fetch('https://clingonaustralia.com.au/login', {
            method: 'POST',
            header: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                'username': userName,
                'password': password
            })
        })
            .then(data => {
                setLoading(false)
                console.log(data.status)
                if (data.status === 200) {
                    successfulLogin()
                } else {
                    setError(true)
                }

            })
            .catch(err => {
                setLoading(false)
                console.error(TypeError)
            })
    }
    function successfulLogin() {
        localStorage.setItem('loggedInUser', true)
        history.push("/store")
        window.location.reload(false)
    }
    useEffect(() => {
        setSessionExpired(localStorage.getItem('sessionExpire') != 'false')
        setTimeout(() => {
            setSessionExpired(false)
            localStorage.setItem('sessionExpire', 'false')
        }, 6000)
    }, [])
    return (
        <Container>
            <Row className="justify-content-md-center">
                <div id='login-container'>
                    <h3>Stockist Login</h3>
                    {sessionExpired && <Alert className='alert' variant='danger'>
                        Session has expired please log in again.
                    </Alert>}
                    {loading && <div style={{width: "100%", display: "flex", justifyContent: "center"}}> <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></div>}
                    {error && <Alert className='alert' variant='danger'>
                        Username or password were incorrect, please try again.
                    </Alert>}
                    <Col sm={{ span: 4, offset: 4 }}>

                        <Form>

                            <Form.Group className="mb-3" controlId="formPlaintextEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control onChange={(e) => setUserName(e.target.value)} type="text" placeholder="Username" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPlaintextPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            <button className='clear-order' style={{ backgroundColor: 'rgba(66,129,143,255)' }} type='submit' onClick={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}>Login</button>{' '}
                        </Form>

                    </Col>
                </div>

            </Row>
        </Container>
    )
}