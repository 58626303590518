import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import '../css/general.css'
import emailjs from 'emailjs-com';
import Alert from 'react-bootstrap/Alert'
import {
    useHistory
  } from "react-router-dom";
  import Spinner from 'react-bootstrap/Spinner';
export default function Cart({ sessionExpire, checkIsLoggedIn, isPhone, setError, error, setEmailError, totalNumOfBrushes, clearOrder, clearOrderAfterConfirm, phoneError, test, orderedItems, orderSubtotal, orderForEmail, updateOrderSubmitted }) {
    let [loading, setLoading] = useState(false)
    var orderedItems = []
    let [numOfBrushesLeft, setNumOfBrushesLeft] = useState(0)

    let history = useHistory()
    function sendEmail(e) {
        e.preventDefault();
        var numOfBrushes = totalNumOfBrushes(test)

        if (numOfBrushes < 12) {
            e.preventDefault()
            setError(true)
            setNumOfBrushesLeft(12 - numOfBrushes)
        } else {
            if (checkIsLoggedIn()){
                setLoading(true)
            fetch("https://clingonaustralia.com.au/order", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(test)

            })
                .then(resp => {
                    setLoading(false)
                    if (resp.status == 200) {
                        updateOrderSubmitted()
                        clearOrderAfterConfirm()
                        if (!isPhone) {
                            setEmailError(false)
                        }
                        setError(false)
                    } else if (resp.status = 403) {
                    localStorage.setItem('sessionExpire', true)

                    }
                    else {
                        if (isPhone) {
                            phoneError()
                        } else {

                            setEmailError(true)
                        }
                    }
                })

                .catch(e => {
                    setEmailError(true)
                    setLoading(false)
                    localStorage.setItem('sessionExpire', true)

                })} else {
                    window.alert("Session has expired, please log in again")

                    sessionExpire()
                }

        }
    }
    let orderForSend = orderedItems.map(each => {
        return `${each.code} X ${each.qty}`
    })
    orderForSend = orderForSend.join("----")
    orderSubtotal = 0
    return (
        <div>
            {

                test.map(order => {
                    orderSubtotal += order.qty * order.price
                    return <div>
                        {order.qty > 0 && <>
                            <p className='order-item'><b>{order.code}</b> X {order.qty}<span style={{ float: 'right' }}>${(order.price * order.qty).toFixed(2)}</span></p>
                            <p className='order-item order-item-price'></p>
                        </>
                        }

                    </div>
                })}
            <br />
            <p style={{ float: 'right' }}>Subtotal: ${orderSubtotal.toFixed(2)}</p>
            <hr className='style-eight' />
            <form onSubmit={sendEmail}>
                {error && <Alert className='alert' variant='danger' style={{ width: '100%' }}>
                    Minimum wholesale order is 12 or more brushes. Please add {numOfBrushesLeft} more brushes to your order
                </Alert>}

                {loading && <div style={{width: "100%", display: "flex", justifyContent: "center"}}> <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></div>}
                {!loading && <><button className='clear-order' type='button' style={{ backgroundColor: 'grey' }} onClick={() => {
                    clearOrder()
                }}>Clear Order</button>
                <button className='clear-order' style={{ display: 'inline-block', float: 'right', backgroundColor: 'rgba(66,129,143,255)' }}>Confirm Order</button></>}
            </form>

        </div>
    )
}
